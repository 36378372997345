export default string => {
    const htmlEntities = {
        '&#39;': '\'',
        '&quot;': '"',
        '&amp;': '&',
        '&lt;': '<',
        '&gt;': '>',
        '&#8217;': '’',
        '&#8216;': '‘',
        '&#8220;': '“',
        '&#8221;': '”',
        '&rsquo;': '’',
        '&lsquo;': '‘',
    };

    return string.replace(/&#?[a-z0-9]+;/gi, entity => htmlEntities[entity] || entity);
};
